.btn-toggle-active{
    background-color: #09d3ac;
}

.setHead{
    font-weight: bold;
}

.letterh5{
    margin-top: 10px;
    margin-bottom: 10px;
}

.slist{
    margin-right: 10px;
}

.slist ul{
    padding-left: 25px;
    margin-top: 10px;
    margin-bottom: 0;
}

.slist li{
    font-size: 0.9em;
    overflow: auto;
    list-style-position: inside;
}

.switch_div{
    display: inline-block;
    float: right;
}

.sbuttonDiv{
    display: flex;
}

.sbutton{
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    margin-bottom: 200px;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .wrapper_setting{
        font-size: 0.8em;
    }
}

/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){
    .wrapper_setting{
        font-size: 0.8em;
    }
}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){
    .wrapper_setting{
        font-size: 0.7em;
    }
}

@media only screen and (max-width: 371px) {
    .wrapper_setting{
        font-size: 0.6em;
    }
}