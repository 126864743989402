.sum_input {
    width: 100%;
    height: 100%;
    position: relative;
}

.sum_value {
    position: absolute;
    top: 0;
    left: 2px;
    font-size: 10px;
    color: #8898aa !important;
}

.sum_left_border {
    border-left: 2px dotted violet !important;
}

.sum_bottom_border {
    border-bottom: 2px dotted violet !important;
}

@media (min-width: 320px) and (max-width: 480px) {
    .sum_value {
        font-size: 5px;
    }
}
