.four_table {
    /* table-layout: fixed; */
    width: 100%;
    border: 4px solid black;
    border-collapse: collapse;

    margin-left: auto;
    margin-right: auto;

    font-family: Calibri, sans-serif;
}

.four_table > tbody > tr > td{
    text-align: center;
    vertical-align: center;
    width: 30px;
    height: 30px;
    white-space: nowrap;
}

@media (min-width: 320px) and (max-width: 480px) {

    .four_table{
        height: 280px;
    }

}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {
    .four_table{
        height: 420px;
    }
}

/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .four_table{
        height: 350px;
    }
}
