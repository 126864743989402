.problem_table {
    width: 450px;
    height: 450px;
    border-collapse: collapse;
}
.problem_table tr{
    /* border: 2px solid black; */
}
.problem_div{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.problem_table_td{
    font-size:8px;
    height: 15px;
    width: 15px;
    text-align: center;
    border: 2px solid black;
    padding: 0;
    overflow: hidden;
}
.problem_table_tr {
    /* border: 2px solid black; */
}
.buttons_div{
    width: 100%;
    margin-top: 30px;
}
.buttons_container, .buttons_container2, .form_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.lebel_side{
    width: 35%;
    text-align: right;
}

.input_side{
    width: 65%;
    padding-left: 5%;
}

.buttons_container2{
    margin-top: 30px;
}
.left_buttons{
    position: relative;
    width: 100px;
    top: -33px;
}
.left_buttons2{
    position: relative;
    width: 31%;
}
.right_buttons2{
    position: relative;
    width: 69%;
}

.btn_up, .btn_down, .btn_left, .btn_right{
    background-color: #dfebf6 !important;
}

.btn_up{
    position: absolute;
    top:-30px;
    left: -30px;
    margin-left: 40%;
}
.btn_down{
    position: absolute;
    top:30px;
    left: -30px;
    margin-left: 40%;
}
.btn_left{
    position: absolute;
    top:0px;
    left: -60px;
    margin-left: 40%;
}
.btn_right{
    position: absolute;
    top:0px;
    left: 40%;
}

.right_buttons{
    position: relative;
    width: 200px;
}
.game_inputs_div{
    display: flex;
}


.bottom_buttons_container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.cell_focused {
    border: 2px solid green !important;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.default_cell {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.btn{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: #f2f2f5;
    border-radius: 3px;
    color: black;
    height: 30px;
    width: 30px;
}

.greyInputButton{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: gray;
    border-radius: 3px;
    color: black;
    height: 30px;
    width: 30px;
    outline: none;
}

.greyInputButtonSelected{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: gray;
    border: 2px solid orange;
    color: black;
    height: 30px;
    width: 30px;
    outline: none;
}

.gameInputButton{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: #f2f2f5;
    border-radius: 3px;
    color: black;
    height: 30px;
    width: 30px;
    outline: none;
}

.gameInputButtonSelected{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: #f2f2f5;
    border: 2px solid orange;
    color: black;
    height: 30px;
    width: 30px;
    outline: none;
}

/*.greyButtonInput:focus{
    border: 2px solid orange;
}*/


.gameInputButton:focus{
    border: 2px solid orange;
}



#doubleSizeBtn{
    width: 61px;
}

#doubleSizeBtn2{
    width: 61px;
    height: 62px;
}

#bodyText{
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 4px;
    font-size: 14px;
}

.header{
    border-bottom: 1px solid black;
    padding-left: 4px;
}

.bigButtonsBody{
    margin-top: 30px;
    margin-bottom: 20px;
    /* background-color: palevioletred; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bodyBtnWhite{
    width: 180px;
    background-color: white;
    color: black;
    font-size: 14px;
    border: 2px solid #3B75C3;
}

.bodyBtnBlue{
    margin-top: 20px;
    width: 180px;
    background-color: #3B75C3;
    color: white;
    font-size: 14px;
    border: 2px solid rgb(18, 87, 177);
}

.smallText{
    height: 20px;
    font-size: 13px;
    color: gray;
    padding-left: 10%;
    margin-top: 2px;
    margin-bottom: 8px;
}

.textBox{
    width: 70%;
    height: 25px;
    border: 1px solid gray;
    border-radius: 4px;
    font-size: 16px;
}

.btnDiv{
    text-align: center;
    margin-bottom: 30px;
}

#centerAllign{
    text-align: center;
    
}

@media (min-width: 320px) and (max-width: 480px) {
    .problem_table {
        width: 300px;
        height: 300px;
    }
    .problem_table_td{
        font-size:8px;
        height: 10px;
        width: 10px;
    }
}
