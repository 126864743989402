@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import url(https://fonts.googleapis.com/css?family=Varela+Round);
.menu {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    margin: 0 auto;
    height: 10vh;
    list-style: none;
    background-color: #424242;
}

.menu-item {
    width: 50px;
    height: 40px;
    transition: background-position-x 0.9s linear;
    text-align: center;
}

.menu-item {
    font-size: 22px;
    color: #777;
    text-decoration: none;
    transition: all 0.45s;
}

.menu-item:hover {
    -webkit-animation: line 1s;
            animation: line 1s;
}

.menu-item:hover a {
    color: #d94f5c;
}

.menu-item:not(:last-child) {
    margin-right: 30px;
}
@-webkit-keyframes line {
    0% {
        background-position-x: 390px;
    }
}
@keyframes line {
    0% {
        background-position-x: 390px;
    }
}

/* ------------------------------------------------------------------------ */


.board_header{
    font-weight: bold;
    border-bottom: 1px solid black;
}


.top-nav {
    width: 100%;
    overflow: auto;
}

.top-nav a{
    border-bottom: 1px solid black;
}

.top-nav .custom_a {
    text-decoration: none;
    float: left;
    width: 18%;
    text-align: center;
    box-shadow: 1px 0px 0px 0px black;
}

.top-nav a:hover {
    font-weight: bold;
    cursor: pointer;
}

.top-nav_active{
    border-bottom: none !important;
}

.nav_box_setting{
    float: right;
    text-decoration: none;
    text-align: center;
    width: 10%;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){

}

@media only screen and (max-width: 371px){


}
.sum_input {
    width: 100%;
    height: 100%;
    position: relative;
}
.input_value{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.memo-table {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

}

.memo-table > tbody {
    display: block;
    width: 100%;
}

.memo-table > tbody > tr {
    width: 100%;
    height: 8px;
}

.memo-table > tbody > tr > td {
    width: 20px;
    font-size: 7px;
}

@media (min-width: 320px) and (max-width: 480px) {
    .memo-table > tbody > tr {
        width: 100%;
        height: 6px;
    }
    .memo-table > tbody > tr > td {
        width: 20px;
        font-size: 5px;
    }
}


.board_table > tbody > tr:nth-child(3n) {
    border-bottom: 2px solid black;
}

.board_table > tbody > tr > td:nth-child(3n) {
    border-right: 2px solid black;
}
/*連続入力の色指定部分*/
.active_input{
    background-color: #285c99;
}

.safe_color {
    color: green;
}
.unsafe_color {
    color: #e6336e;
}
.default_color {
    color: black;
}

.selected_cell {
    background-color: #dfebf6;
}
.highlight_cell {
    background-color: #fffecc !important;
}

.btn_continuous_input_active {
    background-color: #285c99 !important;
}
.btn_memo_input_active{
    background-color: #285c99 !important;
}

.selected_input {
    color: gray;
}

.active_level {
    background-color: #ff9900 !important;
}

/* ------------------------------------------------------------------------ */

/* CSS for the sudoku grid */

.board_wrapper{
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 10px;
}

.board_title{
    font-size: 0.8em;
    margin-left: 10px;
}

.board_table {
    /* table-layout: fixed; */
    width: 100%;
    border: 4px solid black;
    border-collapse: collapse;

    margin-left: auto;
    margin-right: auto;

    font-family: Calibri, sans-serif;
}

.board_table > tbody > tr > td{
    text-align: center;
    vertical-align: center;
    width: 30px;
    height: 30px;
    white-space: nowrap;
}

/* CSS for the number selector {1-9}*/
.number_selector_wrapper{
    margin-top: 15px;
    margin-left: 30px;
    margin-right: 30px;
}

.number_selector_wrapper table{
    border: 2px solid black;
    width: 100%;
    border-collapse: collapse;
}

.number_selector_wrapper table td{
    text-align: center;
    border-right: 1px solid black;
}

.number_selector_wrapper table td:hover{
    font-weight: bold;
    cursor: pointer;
}

/* CSS for stacked buttons */

.stacked_buttons_wrapper{
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 15px;
    overflow: hidden;
}

.btn_grp_left{
    float: left;
    height: 80px;
    width: 23%;
    border: 2px solid black;
}

.btn_grp_left table{
    width: 100%;
    height: 100%;
    border-collapse: collapse;
}

.btn_grp_left table .top_row{
    border-bottom: 2px solid black !important;
}

.btn_grp_left table tr:hover{
    font-weight: bold;
    cursor: pointer;
}

.btn_grp_left table td{
    text-align: center;
}


.btn_grp_right_1{
    float: right;
    height: 80px;
    width: 22%;
    border: 2px solid black;
    border-left: none;
}

.btn_grp_right_1 table{
    width: 100%;
    height: 100%;
    border-collapse: collapse;
}

.btn_grp_right_1 table tr:hover{
    font-weight: bold;
    cursor: pointer;
}

.btn_grp_right_1 table td{
    text-align: center;
}

.btn_grp_right_2{
    height: 80px;
    width: 22%;
    float: right;
    border: 2px solid black;
    border-left: none;
}

.btn_grp_right_2 table{
    width: 100%;
    height: 100%;
    border-collapse: collapse;
}

.btn_grp_right_2 table tr:hover{
    font-weight: bold;
    cursor: pointer;
}

.btn_grp_right_2 table td{
    text-align: center;
}


.btn_grp_right_3{
    float: right;
    height: 80px;
    width: 22%;
    border: 2px solid black;
}

.btn_grp_right_3 table{
    width: 100%;
    height: 100%;
    border-collapse: collapse;
}

.btn_grp_right_3 table .top_row{
    border-bottom: 2px solid black;
}

.btn_grp_right_3 table tr{
    width: 100%;
}

.btn_grp_right_3 table tr:hover{
    font-weight: bold;
    cursor: pointer;
}

.btn_grp_right_3 table td{
    text-align: center;
}

.answer_button_div{
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 15px;
    overflow: hidden;
}

.answer_button_div button{
    width: 100%;
    border: 1px solid #4972C1;
    border-radius: 5px;
    background-color: white;
    color: black;
    cursor: pointer;
}

.div_below_answer_btn{
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 15px;
    overflow: hidden;
}

.bigbtn3 {
    background-color: #4972C1;
    border-color: #285c99;
    color: white;
    width: 45%;
    border-radius: 6px;
    float: left;
}

.bigbtn4 {
    background-color: #4972C1;
    border-color: #285c99;
    color: #FFFFFF;
    width: 45%;
    border-radius: 6px;
    float: right;
}

.level_selector_group{
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.level_selector_group .level_button {
    background-color: #4972C1;
    border-color: #285c99;
    color: #FFFFFF;
    border-radius: 5px;
    width: 15%;
}

.wrapper_page_end_buttons{
    overflow: hidden;
}

.div_below_level_grp{
    float: left;
    margin-top: 35px;
    margin-left: 15px;
    width: 70%;
}

.group_btn_text{
    overflow: hidden;
}

.bigbtn5 {
    background-color: #4972C1;
    border-color: #285c99;
    color: #FFFFFF;
    width: 35%;
    border-radius: 6px;
    float: left;
    clear: left;
    margin-bottom: 10px;
}

.last_div{
    float: left;
    clear: left;
    margin-top: 5px;
    margin-left: 15px;
    width: 100%;
}

.last_div .top_button{
    margin-top: 0 !important;
}

.bigbtn6 {
    background-color: #629BD2;
    border-color: #285c99;
    color: #FFFFFF;
    width: 50%;
    border-radius: 6px;
    float: left;
    clear: left;
    margin-top: 10px;
}

.letterh4{
    margin-top: 35px;
    margin-left: 15px;
    margin-bottom: 0;
}

.letterh4:hover{
    cursor: pointer;
}

.white_box{
    border: 1px gray solid;
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    cursor: pointer;
    margin-bottom: 30px;
}

.white_box a{
    color: black;
    text-decoration: none !important;
}

#last_span{
    font-size: 0.8em;
    color: blue;
    margin-top: 30px;
    cursor: pointer;
    padding-left: 3px;
}

#board_footer{
    background-color: #7F7F7F;
    color: white;
    font-size: 0.8em;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 3px;
    cursor: pointer;
}

#board_footer a{
    text-decoration: none;
    color: white;
}


/* hi-res laptops and desktops */
@media (min-width: 1281px) {
    .board_table{
        height: 420px;
    }
}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .board_table{
        height: 350px;
    }

    .stacked_buttons_wrapper{
        font-size: 0.9em;
    }

    .small_font_row{
        font-size: 0.8em;
    }
}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){
    .stacked_buttons_wrapper{
        font-size: 0.7em;
    }

    .small_font_row{
        font-size: 0.7em;
    }
}

@media only screen and (max-width: 371px){

    .board_table{
        font-size: 0.9em;
        height: 200px;
    }

    .number_selector_wrapper{
        font-size: 0.9em;
    }

    .stacked_buttons_wrapper{
        font-size: 0.6em;
    }

    .small_font_row{
        font-size: 0.5em;
    }
}

.four_table {
    /* table-layout: fixed; */
    width: 100%;
    border: 4px solid black;
    border-collapse: collapse;

    margin-left: auto;
    margin-right: auto;

    font-family: Calibri, sans-serif;
}

.four_table > tbody > tr > td{
    text-align: center;
    vertical-align: center;
    width: 30px;
    height: 30px;
    white-space: nowrap;
}

@media (min-width: 320px) and (max-width: 480px) {

    .four_table{
        height: 280px;
    }

}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {
    .four_table{
        height: 420px;
    }
}

/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .four_table{
        height: 350px;
    }
}

.diagonal_cell{
    background-color: #CCC;
}
.jigsaw_table {
    /* table-layout: fixed; */
    width: 100%;
    border: 4px solid black;
    border-collapse: collapse;

    margin-left: auto;
    margin-right: auto;

    font-family: Calibri, sans-serif;
}

.jigsaw_table > tbody > tr > td{
    text-align: center;
    vertical-align: center;
    width: 30px;
    height: 30px;
    white-space: nowrap;
}

.left_border {
    border-left: 3px solid violet !important;
}

.right_border {
    border-right: 3px solid violet !important;
}

.top_border {
    border-top: 3px solid violet !important;
}

.bottom_border {
    border-bottom: 3px solid violet !important;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {
    .jigsaw_table{
        height: 420px;
    }
}

/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .jigsaw_table{
        height: 350px;
    }
}

.sum_input {
    width: 100%;
    height: 100%;
    position: relative;
}

.sum_value {
    position: absolute;
    top: 0;
    left: 2px;
    font-size: 10px;
    color: #8898aa !important;
}

.sum_left_border {
    border-left: 2px dotted violet !important;
}

.sum_bottom_border {
    border-bottom: 2px dotted violet !important;
}

@media (min-width: 320px) and (max-width: 480px) {
    .sum_value {
        font-size: 5px;
    }
}

.sum_table {
    /* table-layout: fixed; */
    width: 100%;
    border: 4px solid black;
    border-collapse: collapse;

    margin-left: auto;
    margin-right: auto;

    font-family: Calibri, sans-serif;
}

.sum_table > tbody > tr > td{
    text-align: center;
    vertical-align: center;
    width: 30px;
    height: 30px;
    white-space: nowrap;
}

.sum_table > tbody > tr:nth-child(3n) {
    border-bottom: 2px solid black;
}

.sum_table > tbody > tr > td:nth-child(3n) {
    border-right: 2px solid black;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {
    .sum_table{
        height: 420px;
    }
}

/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .sum_table{
        height: 350px;
    }
}

.btn-toggle-active{
    background-color: #09d3ac;
}

.setHead{
    font-weight: bold;
}

.letterh5{
    margin-top: 10px;
    margin-bottom: 10px;
}

.slist{
    margin-right: 10px;
}

.slist ul{
    padding-left: 25px;
    margin-top: 10px;
    margin-bottom: 0;
}

.slist li{
    font-size: 0.9em;
    overflow: auto;
    list-style-position: inside;
}

.switch_div{
    display: inline-block;
    float: right;
}

.sbuttonDiv{
    display: -webkit-flex;
    display: flex;
}

.sbutton{
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    margin-bottom: 200px;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .wrapper_setting{
        font-size: 0.8em;
    }
}

/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){
    .wrapper_setting{
        font-size: 0.8em;
    }
}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){
    .wrapper_setting{
        font-size: 0.7em;
    }
}

@media only screen and (max-width: 371px) {
    .wrapper_setting{
        font-size: 0.6em;
    }
}
.btn-toggle-active{
    background-color: #09d3ac;
}

.setHead{
    font-weight: bold;
}

.letterh5{
    margin-top: 10px;
    margin-bottom: 10px;
}

.slist{
    margin-right: 10px;
}

.slist ul{
    padding-left: 25px;
    margin-top: 10px;
    margin-bottom: 0;
}

.slist li{
    font-size: 0.9em;
    overflow: auto;
    list-style-position: inside;
}

.switch_div{
    display: inline-block;
    float: right;
}

.sbuttonDiv{
    display: -webkit-flex;
    display: flex;
}

.sbutton{
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    margin-bottom: 200px;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .wrapper_setting{
        font-size: 0.8em;
    }
}

/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){
    .wrapper_setting{
        font-size: 0.8em;
    }
}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){
    .wrapper_setting{
        font-size: 0.7em;
    }
}

@media only screen and (max-width: 371px) {
    .wrapper_setting{
        font-size: 0.6em;
    }
}
.btn-toggle-active{
    background-color: #09d3ac;
}

.setHead{
    font-weight: bold;
}

.letterh5{
    margin-top: 10px;
    margin-bottom: 10px;
}

.slist{
    margin-right: 10px;
}

.slist ul{
    padding-left: 25px;
    margin-top: 10px;
    margin-bottom: 0;
}

.slist li{
    font-size: 0.9em;
    overflow: auto;
    list-style-position: inside;
}

.switch_div{
    display: inline-block;
    float: right;
}

.sbuttonDiv{
    display: -webkit-flex;
    display: flex;
}

.sbutton{
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    margin-bottom: 200px;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .wrapper_setting{
        font-size: 0.8em;
    }
}

/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){
    .wrapper_setting{
        font-size: 0.8em;
    }
}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){
    .wrapper_setting{
        font-size: 0.7em;
    }
}

@media only screen and (max-width: 371px) {
    .wrapper_setting{
        font-size: 0.6em;
    }
}
.btn-toggle-active{
    background-color: #09d3ac;
}

.setHead{
    font-weight: bold;
}

.letterh5{
    margin-top: 10px;
    margin-bottom: 10px;
}

.slist{
    margin-right: 10px;
}

.slist ul{
    padding-left: 25px;
    margin-top: 10px;
    margin-bottom: 0;
}

.slist li{
    font-size: 0.9em;
    overflow: auto;
    list-style-position: inside;
}

.switch_div{
    display: inline-block;
    float: right;
}

.sbuttonDiv{
    display: -webkit-flex;
    display: flex;
}

.sbutton{
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    margin-bottom: 200px;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .wrapper_setting{
        font-size: 0.8em;
    }
}

/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){
    .wrapper_setting{
        font-size: 0.8em;
    }
}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){
    .wrapper_setting{
        font-size: 0.7em;
    }
}

@media only screen and (max-width: 371px) {
    .wrapper_setting{
        font-size: 0.6em;
    }
}

.btn-toggle-active{
    background-color: #09d3ac;
}

.setHead{
    font-weight: bold;
}

.letterh5{
    margin-top: 10px;
    margin-bottom: 10px;
}

.slist{
    margin-right: 10px;
}

.slist ul{
    padding-left: 25px;
    margin-top: 10px;
    margin-bottom: 0;
}

.slist li{
    font-size: 0.9em;
    overflow: auto;
    list-style-position: inside;
}

.switch_div{
    display: inline-block;
    float: right;
}

.sbuttonDiv{
    display: -webkit-flex;
    display: flex;
}

.sbutton{
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    margin-bottom: 200px;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .wrapper_setting{
        font-size: 0.8em;
    }
}

/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){
    .wrapper_setting{
        font-size: 0.8em;
    }
}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){
    .wrapper_setting{
        font-size: 0.7em;
    }
}

@media only screen and (max-width: 371px) {
    .wrapper_setting{
        font-size: 0.6em;
    }
}

.wrapper{

}

.header{
    font-weight: bold;
}

.box1{
    margin-left: 30px;
    margin-right: 30px;
}

.boxbuttonTop, .boxbuttonMiddle {
    background-color: #1E70BD;
    color: white;
    padding: 5px 32px;
    text-align: center;
    font-size: 0.9em;
    letter-spacing: 0.1em;
    margin: 4px 2px;
    margin-top: 30px;
    cursor: pointer;
}

.boxbuttonBottom{
    color: black;
    border: 1px gainsboro solid;
    text-align: center;
    font-size: 0.9em;
    margin: 4px 2px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.myfooter{
    background-color: #7F7F7F;
    color: white;
    font-size: 0.7em;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.myfooter a{
    text-decoration: none;
    color: white;
}


/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){

}

@media only screen and (max-width: 371px){

    .header{
        font-size: 0.9em;
    }

    .boxbuttonBottom, .boxbuttonMiddle, .boxbuttonTop{
        font-size: 0.7em;
    }

    .myfooter{
        font-size: 0.6em;
    }
}
.secondNav{
    display: -webkit-flex;
    display: flex;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.secondNav > div{
    box-sizing: border-box;
    width: 33.33%;
    text-align: center;
    border: 1px solid black;
}

.secondNav > div:first-child{
    border-right: none;
}

.secondNav > div:nth-child(2){
    border-right: none;
}

.table-div{
    margin-top: 20px;
    margin-left: 50px;
    margin-right: 50px;
}

.table-containing-boardbygrid{
    width: 100%;
}

.table-containing-boardbygrid td{
    box-sizing: border-box;
    text-align: center;
    font-size: 0.8em;
}

.table-containing-boardbygrid td:nth-child(1){
    width: 10%;
}

.table-containing-boardbygrid td:nth-child(2){
    width: 70%;
}

.table-containing-boardbygrid td:nth-child(3){
    text-align: right;
    width: 20%;
}

.table-containing-boardbygrid button{
    font-size: 0.8em;
    padding-left: 15px;
    padding-right: 15px;
}

.footer{
    cursor: pointer;
    margin-top: 20px;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

@media only screen and (max-width: 479px) {
    .top-nav{
        font-size: 0.8em;
    }

    .secondNav{
        font-size: 0.8em;
        margin-right: 15px;
        margin-left: 15px;
    }

    .table-containing-boardbygrid td{
        font-size: 0.7em;
    }

    .table-containing-boardbygrid button{
        padding-left: 5px;
        padding-right: 5px;
        height: 25px;
    }
    .footer{
        font-size: 0.8em;
    }
}
.problem_table {
    width: 450px;
    height: 450px;
    border-collapse: collapse;
}
.problem_table tr{
    /* border: 2px solid black; */
}
.problem_div{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}
.problem_table_td{
    font-size:8px;
    height: 15px;
    width: 15px;
    text-align: center;
    border: 2px solid black;
    padding: 0;
    overflow: hidden;
}
.problem_table_tr {
    /* border: 2px solid black; */
}
.buttons_div{
    width: 100%;
    margin-top: 30px;
}
.buttons_container, .buttons_container2, .form_container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.lebel_side{
    width: 35%;
    text-align: right;
}

.input_side{
    width: 65%;
    padding-left: 5%;
}

.buttons_container2{
    margin-top: 30px;
}
.left_buttons{
    position: relative;
    width: 100px;
    top: -33px;
}
.left_buttons2{
    position: relative;
    width: 31%;
}
.right_buttons2{
    position: relative;
    width: 69%;
}

.btn_up, .btn_down, .btn_left, .btn_right{
    background-color: #dfebf6 !important;
}

.btn_up{
    position: absolute;
    top:-30px;
    left: -30px;
    margin-left: 40%;
}
.btn_down{
    position: absolute;
    top:30px;
    left: -30px;
    margin-left: 40%;
}
.btn_left{
    position: absolute;
    top:0px;
    left: -60px;
    margin-left: 40%;
}
.btn_right{
    position: absolute;
    top:0px;
    left: 40%;
}

.right_buttons{
    position: relative;
    width: 200px;
}
.game_inputs_div{
    display: -webkit-flex;
    display: flex;
}


.bottom_buttons_container{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.cell_focused {
    border: 2px solid green !important;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.default_cell {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.btn{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: #f2f2f5;
    border-radius: 3px;
    color: black;
    height: 30px;
    width: 30px;
}

.greyInputButton{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: gray;
    border-radius: 3px;
    color: black;
    height: 30px;
    width: 30px;
    outline: none;
}

.greyInputButtonSelected{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: gray;
    border: 2px solid orange;
    color: black;
    height: 30px;
    width: 30px;
    outline: none;
}

.gameInputButton{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: #f2f2f5;
    border-radius: 3px;
    color: black;
    height: 30px;
    width: 30px;
    outline: none;
}

.gameInputButtonSelected{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: #f2f2f5;
    border: 2px solid orange;
    color: black;
    height: 30px;
    width: 30px;
    outline: none;
}

/*.greyButtonInput:focus{
    border: 2px solid orange;
}*/


.gameInputButton:focus{
    border: 2px solid orange;
}



#doubleSizeBtn{
    width: 61px;
}

#doubleSizeBtn2{
    width: 61px;
    height: 62px;
}

#bodyText{
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 4px;
    font-size: 14px;
}

.header{
    border-bottom: 1px solid black;
    padding-left: 4px;
}

.bigButtonsBody{
    margin-top: 30px;
    margin-bottom: 20px;
    /* background-color: palevioletred; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.bodyBtnWhite{
    width: 180px;
    background-color: white;
    color: black;
    font-size: 14px;
    border: 2px solid #3B75C3;
}

.bodyBtnBlue{
    margin-top: 20px;
    width: 180px;
    background-color: #3B75C3;
    color: white;
    font-size: 14px;
    border: 2px solid rgb(18, 87, 177);
}

.smallText{
    height: 20px;
    font-size: 13px;
    color: gray;
    padding-left: 10%;
    margin-top: 2px;
    margin-bottom: 8px;
}

.textBox{
    width: 70%;
    height: 25px;
    border: 1px solid gray;
    border-radius: 4px;
    font-size: 16px;
}

.btnDiv{
    text-align: center;
    margin-bottom: 30px;
}

#centerAllign{
    text-align: center;
    
}

@media (min-width: 320px) and (max-width: 480px) {
    .problem_table {
        width: 300px;
        height: 300px;
    }
    .problem_table_td{
        font-size:8px;
        height: 10px;
        width: 10px;
    }
}

.problemInputDot {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.problemInputDot::before {
    background-color: black;
    height: 7%;
    width: 7%;
    border-radius: 50%;
    content: '';
}

.problemInputCross {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.problemInputCross::before, .problemInputCross::after {
    background-color: black;
    height: 100%;
    width: 1%;
    content: '';
    -webkit-transform: rotate(45deg) translate(0%, 0%);
            transform: rotate(45deg) translate(0%, 0%);
}
.problemInputCross::after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.td_data{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.problemInputGray {
    width: 100%;
    height: 100%;
    background-color: gray;
}

.problem_blank_cell {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    
}

.completed_input {
    position: relative;
}

.completed_input::before {
    content: "X";
    /* font-size: 8px; */
    opacity: .7;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.td_data {    
    width: 100%;
    height: 100%;
}

.dot{
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.cross{
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.gray {
    width: 100%;
    height: 100%;
    background-color: gray;
    /* font-size: 10px; */
}

.default_cell {
    /* border: 2px solid black; */
    /* font-size: 10px; */
}

.safe_cell {
    border: 2px solid green;
}

.unsafe_cell {
    border: 2px solid red;
}

.invalid_cell {
    color: #e6336e;
}

/* .problem_cell{
    width: 100%;
    height: 100%;
    text-align: center;
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blank_cell {
    width: 100%;
    height: 100%;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty_problem_cell{
    flex: 0 !important;
    border: none !important;
}

.cell_focused {
    border: 2px solid green !important;
} */

.black_border {
    border: 1px solid black;
}


#howToPlayDetailsHeader {
    border-bottom: 1px black solid;
    font-weight: bold;
}

#tipTitle {
    margin-top: 5px;
    margin-bottom: 10px;
}

.tableOfContents {
    margin-left: 30px;
    width: 40%;
    overflow: auto;
}

.tableOfContents #title {
    margin-left: 5px;
}

.tableOfContents ol {
    padding-left: 25px;
    margin-top: 0;
    color: #285c99;
}

.content {
    margin-left: 10px;
    margin-right: 15px;
}

.content h3 {
    margin-bottom: 0;
}

.content p {
    margin-top: 0;
}

.content .images {
    height: 100px;
    width: 100px;
    float: right;
    margin-left: 15px;
    margin-bottom: 15px;
    display: inline-block;
}

#secondLastFooter {
    background-color: #DFEAF5;
    text-align: center;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    cursor: pointer;
}

#lastFooter {
    background-color: #4A72C1;
    text-align: center;
    cursor: pointer;
    color: white;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

    #parent_wrapper {
        font-size: 0.8em;
    }
}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px) {

    #parent_wrapper {
        font-size: 0.8em;
    }

    .tableOfContents {
        margin-left: 15px;
    }
}

@media only screen and (max-width: 371px) {

    #parent_wrapper {
        font-size: 0.7em;
    }

    .tableOfContents {
        margin-left: 12px;
    }
}

/* Naima's Design*/

.articleBody {
    padding-left: 1.5%;
    padding-right: 1%;
    margin-bottom: 15%;
}

.letterh4 {
    font-weight: bold;
    font-size: 20px;
    margin-left: 2%;
    padding-left: 10px;
    margin-right: 2%;
}

.letterUn {
    padding-top: 1.8%;
    font-size: 18px;
    margin-left: 2%;
    margin-right: 2%;
    text-decoration: none;
}

.articleBody {
    padding-left: 1.5%;
    padding-right: 1%;
    margin-bottom: 15%;
}

.titlebox {
    background-color: #EEF6E9;
    border-radius: 5px;
    border: none;
    width: 90%;
    margin: 0 auto;
}

.titlebox .a_box {
    display: -webkit-flex;
    display: flex;
}

.num {
    padding-left: 20px;
    padding-bottom: 20px;
    color: #fff;
}

.title_div {
    display: -webkit-flex;
    display: flex;
}

.title_div button {
    width: 50px;
    border: 1px solid #5C8E38;
    border-radius: 5px;
    background-color: #5C8E38;
    margin-bottom: 5%;
}

.title_div span {
    font-size: 18px;
    margin-left: 15px;
    color: #000;
}

.a_title {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.a_title h4 {
    position: relative;
    display: inline-block;
}

.para_title {
    position: relative;
    border-bottom: 4px dashed #AAC680;
    border-left: 8px solid #AAC680;
}

.para_title2 {
    position: relative;
    border: 2px solid #5C8E38;
    background-color: #AAC680;
    border-radius: 5px;
}

.para {
    margin-left: 5%;
    border: none;
    font-size: 16px;
    margin-bottom: 2%;
}
.how_to_play_titles_header{
    border-bottom: 1px solid black;
    font-weight: bold;
    font-size: 17px;
}

.howToPlayTitles{
    padding-left: 26px;
    margin-top: 5px;
}

.howToPlayTitles li{
    cursor: pointer;
    color: blue;
}

.tipTitles{
    padding-left: 26px;
    margin-top: 5px;
}

.tipTitles li{
    cursor: pointer;
    color: blue;
}

.howTo_tip_title_page_footer{
    background-color: #3B75C4;
    text-align: center;
    color: white;
    cursor: pointer;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){

}

@media only screen and (max-width: 371px) {
}


/* make the table shape square*/
/*write media query for the table so that it can be responsive*/


.footer{
    background-color: #3B75C4;
    text-align: center;
    color: white;
}

.section{
    border: 1px solid black;
    background-color: #BAD7EE;
}

button{
    border-radius: 5px;
    box-shadow: none;
    background-color: #3B75C3;
    color: white;
    padding-top: 0;
    height: 30px;
    float: right;
}

table{
    width: 200px;
    border: none;
}

tbody{
    border: none;
}

td{
}

td, tr{
    border: none;
}

input{
    height: 15px;
    width: 15px;
    box-shadow: none;
    border-style: none;
    float: left;
}


#hi_rate_parent_wrapper{
    overflow: hidden;
}

#hi_rate_top_nav{
    margin-bottom: 30px;
}

#hi_rate_header{
    font-weight: bold;
    border-bottom: 1px solid black;
}

#hi_rate_group{
    margin-left: 30px;
    margin-right: 30px;
    clear: right;
}

#hi_rate_group table{
    margin: 0 auto;
    width: 100% !important;
}

#hi_rate_group table th{
    text-align: center;
    font-weight: normal;
}

#hi_rate_group table td{
    text-align: center;
}

#table_hide_header th{
    visibility: hidden;
}

#hi_rate_whiteButton{
    float: right;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: white;
    color: black;
    border: 2px solid #4A72C1;
    width: 150px;
}

#hi_rate_footer{
    padding: 3px;
    background-color: #4A72C1;
    color: white;
    text-align: center;
    font-size: 0.9em;
    clear: right;
    cursor: pointer;
}

.hi_rate_start_button{
    border: 1px solid gray;
    border-radius: 5px;
    box-shadow: none;
    background-color: #4A72C1;
    color: white;
    padding-top: 0;
    height: 30px;
    width: 70px;
    margin: 0;
    float: right !important;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){

}

@media only screen and (max-width: 371px){
    #hi_rate_parent_wrapper{
        font-size: 0.7em;
    }

    #hi_rate_whiteButton{
        font-size: 0.7em;
        width: 100px;
        height: 20px;
    }

    .hi_rate_start_button{
        font-size: 0.7em;
        height: 20px;
        width: 50px;
    }

}
.menu {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    margin: 0 auto;
    height: 10vh;
    list-style: none;
    background-color: #424242;
}

.menu-item {
    width: 50px;
    height: 40px;
    transition: background-position-x 0.9s linear;
    text-align: center;
}

.menu-item {
    font-size: 22px;
    color: #777;
    text-decoration: none;
    transition: all 0.45s;
}

.menu-item:hover {
    -webkit-animation: line 1s;
            animation: line 1s;
}

.menu-item:hover a {
    color: #d94f5c;
}

.menu-item:not(:last-child) {
    margin-right: 30px;
}
@-webkit-keyframes line {
    0% {
        background-position-x: 390px;
    }
}
@keyframes line {
    0% {
        background-position-x: 390px;
    }
}

/* ------------------------------------------------------------------------ */


.board_header{
    font-weight: bold;
    border-bottom: 1px solid black;
}


.top-nav {
    width: 100%;
    overflow: auto;
}

.top-nav a{
    border-bottom: 1px solid black;
}

.top-nav .custom_a {
    float: left;
    width: 18%;
    text-align: center;
    box-shadow: 1px 0px 0px 0px black;
}

.top-nav a:hover {
    font-weight: bold;
    cursor: pointer;
}

.top-nav_active{
    border-bottom: none !important;
}

.nav_box_setting{
    float: right;
    text-decoration: none;
    text-align: center;
    width: 10%;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){

}

@media only screen and (max-width: 371px){


}
.menu {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
    margin: 0 auto;
    height: 10vh;
    list-style: none;
    background-color: #424242;
}

.menu-item {
    width: 50px;
    height: 40px;
    transition: background-position-x 0.9s linear;
    text-align: center;
}

.menu-item {
    font-size: 22px;
    color: #777;
    text-decoration: none;
    transition: all 0.45s;
}

.menu-item:hover {
    -webkit-animation: line 1s;
            animation: line 1s;
}

.menu-item:hover a {
    color: #d94f5c;
}

.menu-item:not(:last-child) {
    margin-right: 30px;
}
@-webkit-keyframes line {
    0% {
        background-position-x: 390px;
    }
}
@keyframes line {
    0% {
        background-position-x: 390px;
    }
}

/* ------------------------------------------------------------------------ */


.board_header{
    font-weight: bold;
    border-bottom: 1px solid black;
}


.top-nav {
    width: 100%;
    overflow: auto;
}

.top-nav a{
    border-bottom: 1px solid black;
}

.top-nav .custom_a {
    float: left;
    width: 18%;
    text-align: center;
    box-shadow: 1px 0px 0px 0px black;
}

.top-nav a:hover {
    font-weight: bold;
    cursor: pointer;
}

.top-nav_active{
    border-bottom: none !important;
}

.nav_box_setting{
    float: right;
    text-decoration: none;
    text-align: center;
    width: 10%;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){

}

@media only screen and (max-width: 371px){


}

#parent_wrapper{
    overflow: hidden;
}

#header{
    font-weight: bold;
    border-bottom: 1px solid black;
}

#topBlueButtonDiv{
    width: 100%;
    display: -webkit-flex;
    display: flex;
    margin-top: 23px;
    margin-bottom: 23px;
}

button{
    float: none;
}

#topBlueButtonDiv button{
    background-color: #4A72C1;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

#whiteButton{
    float: right;
    margin-right: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: white;
    color: black;
    border: 2px solid #4A72C1;
}

#group{
    margin-left: 30px;
    margin-right: 30px;
    clear: right;
}


#titleDesign{
    text-align: center;
    width: 100%;
    background: #DEE9F4;
    cursor: pointer;
}

#content{
    display: block;
}

#content table{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

#content table td{
    padding: 0;
}

.start_button{
    border: 1px solid gray;
    border-radius: 5px;
    box-shadow: none;
    background-color: #4A72C1;
    color: white;
    padding-top: 0;
    height: 30px;
    width: 70px;
    margin: 0;
    float: right !important;
}

#footer{
    padding: 3px;
    background-color: #4A72C1;
    color: white;
    text-align: center;
    font-size: 0.9em;
    clear: right;
    cursor: pointer;
}
* {
    box-sizing: border-box;
}

.illustrologyMainNavbar{
    display: -webkit-flex;
    display: flex;
}

.illustrologyMainNavbar > a{
    width: 50%;
    text-align: center;
    border-bottom: 1px solid #000;
    text-decoration: none;
    color: black;
}

.illustrologyMainNavbar > a:first-child{
    border-right: 1px solid #000;
}

.active-navbutton{
    border-bottom: none !important;
}

.select-by-difficulty{
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.blue-title{
    text-align: center;
    border: 1px solid #9FC2E3;
}

.centerButton{
    position: relative;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
}

.button-illustrology-main{
    width: 100px;
}

.select-by-difficulty > .first-button{
    margin-top: 20px;
}

.select-by-difficulty > button{
    margin-top: 2px;
}

.select-by-grid{
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.select-by-grid > .first-button{
    margin-top: 20px;
}

.select-by-grid > button{
    margin-top: 2px;
}

.new-questions-div{
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.new-questions-div > table{
    width: 100%;
    margin-top: 20px;
    text-align: center;
    border-collapse: collapse;
}

.new-questions-div > table th{
    background-color: #BFD6ED;
    font-weight: normal;
    font-size: 0.7em;
    border: 1px solid #9FC2E3;
}

.new-questions-div > table td{
    font-size: 0.7em;
    border: 1px solid #9FC2E3;
}

.article-div{
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.article-div li{
    font-size: 0.7em;
}

.article-div li:hover{
    color: blue;
    cursor: pointer;
}

.advertisement-div{
    border: 1px solid #E2E3E3;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 20px;
    text-align: center;
    padding: 20px;
}

.link{
    color: blue;
    display: block;
    margin-top: 20px;
    font-size: 0.8em;
    cursor: pointer;
}

.footer-illustrology-main{
    background-color: #7F7F7F;
    height: 45px;
    position: relative;
}

.footer-illustrology-main > a{
    text-decoration: none;
    color: white;
    font-size: 0.7em;
    position: absolute;
    top: 3px;
    left: 5px;
}

#footer-last-link{
    top: 25px;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){

}

@media only screen and (max-width: 371px) {
    .select-by-difficulty{
        margin-left: 15px;
        margin-right: 15px;
        font-size: 0.8em;
    }

    .select-by-grid{
        margin-left: 15px;
        margin-right: 15px;
        font-size: 0.8em;
    }

    .new-questions-div{
        margin-left: 15px;
        margin-right: 15px;
        font-size: 0.8em;
    }

    .article-div{
        margin-left: 15px;
        margin-right: 15px;
        font-size: 0.8em;
    }

    .advertisement-div{
        margin-left: 15px;
        margin-right: 15px;   
        font-size: 0.8em;     
    }
}


.innerContent{
    margin-left: 30px;
    font-size: 0.7em;
}

.button-div{
    margin-top: 20px;
}

.button-div > button{
    width: 150px;
    position: relative;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
}

.advertisement-div{
    padding: 10px;
}

.secondNav{
    display: -webkit-flex;
    display: flex;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}
.active_sort{
    border: 1px solid #e6336e !important;
}
.secondNav > div{
    box-sizing: border-box;
    width: 33.33%;
    text-align: center;
    border: 1px solid black;
}

.secondNav > div:first-child{
    border-right: none;
}

.secondNav > div:nth-child(2){
    border-right: none;
}

.table-div{
    margin-top: 20px;
    margin-left: 50px;
    margin-right: 50px;
}

.table-containing-boardbylevels{
    width: 100%;
}

.table-containing-boardbylevels td{
    box-sizing: border-box;
    text-align: center;
    font-size: 0.8em;
}

.table-containing-boardbylevels td:nth-child(1){
    width: 10%;
}

.table-containing-boardbylevels td:nth-child(2){
    width: 70%;
}

.table-containing-boardbylevels td:nth-child(3){
    text-align: right;
    width: 20%;
}

.table-containing-boardbylevels button{
    font-size: 0.8em;
    padding-left: 15px;
    padding-right: 15px;
}

.footer{
    cursor: pointer;
    margin-top: 20px;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

@media only screen and (max-width: 479px) {
    .top-nav{
        font-size: 0.8em;
    }

    .secondNav{
        font-size: 0.8em;
        margin-right: 15px;
        margin-left: 15px;
    }

    .table-containing-boardbylevels td{
        font-size: 0.7em;
    }

    .table-containing-boardbylevels button{
        padding-left: 5px;
        padding-right: 5px;
        height: 25px;
    }
    .footer{
        font-size: 0.8em;
    }
}
li, .content h3{
    cursor: pointer;
}
.game_div {
    width: 100%;
    height: 47vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.game_table {
    /* background-color:#f2f2f5; */
}

.game_table_submit {
    /* width:min(100%, 47vh);
    height:47vh; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin: auto;
}

.game_table_tr_submit{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
}
.game_table_tr {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.game_table_td_submit {
    display: -webkit-flex;
    display: flex;
    /* flex: 1; */
    text-align: center;
}

.game_table_td {
    text-align: center;
}

/* GRID 10x10 */
.game_table_tr_10 td:nth-child(n+6) {
    border: 1px solid black;
}
/* GRID 15x15 */
.game_table_tr_15 td:nth-child(n+9) {
    border: 1px solid black;
}
/* GRID 20x20 */
.game_table_tr_20 td:nth-child(n+11) {
    border: 1px solid black;
}
/* GRID 25x25 */
.game_table_tr_25 td:nth-child(n+14) {
    border: 1px solid black;
}
/* GRID 30x30 */
.game_table_tr_30 td:nth-child(n+16) {
    border: 1px solid black;
}

/* GRID 10x10 */
.game_table_tr_10:nth-child(1) td,
.game_table_tr_10:nth-child(2) td,
.game_table_tr_10:nth-child(3) td,
.game_table_tr_10:nth-child(4) td,
.game_table_tr_10:nth-child(5) td {
    border: none !important;
}

/* GRID 15x15 */
.game_table_tr_15:nth-child(1) td,
.game_table_tr_15:nth-child(2) td,
.game_table_tr_15:nth-child(3) td,
.game_table_tr_15:nth-child(4) td,
.game_table_tr_15:nth-child(5) td,
.game_table_tr_15:nth-child(6) td,
.game_table_tr_15:nth-child(7) td,
.game_table_tr_15:nth-child(8) td {
    border: none !important;
}
/* GRID 20x20 */
.game_table_tr_20:nth-child(1) td,
.game_table_tr_20:nth-child(2) td,
.game_table_tr_20:nth-child(3) td,
.game_table_tr_20:nth-child(4) td,
.game_table_tr_20:nth-child(5) td,
.game_table_tr_20:nth-child(6) td,
.game_table_tr_20:nth-child(7) td,
.game_table_tr_20:nth-child(8) td,
.game_table_tr_20:nth-child(9) td,
.game_table_tr_20:nth-child(10) td {
    border: none !important;
}
/* GRID 25x25 */
.game_table_tr_25:nth-child(1) td,
.game_table_tr_25:nth-child(2) td,
.game_table_tr_25:nth-child(3) td,
.game_table_tr_25:nth-child(4) td,
.game_table_tr_25:nth-child(5) td,
.game_table_tr_25:nth-child(6) td,
.game_table_tr_25:nth-child(7) td,
.game_table_tr_25:nth-child(8) td,
.game_table_tr_25:nth-child(9) td,
.game_table_tr_25:nth-child(10) td,
.game_table_tr_25:nth-child(11) td,
.game_table_tr_25:nth-child(12) td,
.game_table_tr_25:nth-child(13) td {
    border: none !important;
}
/* GRID 30x30 */
.game_table_tr_30:nth-child(1) td,
.game_table_tr_30:nth-child(2) td,
.game_table_tr_30:nth-child(3) td,
.game_table_tr_30:nth-child(4) td,
.game_table_tr_30:nth-child(5) td,
.game_table_tr_30:nth-child(6) td,
.game_table_tr_30:nth-child(7) td,
.game_table_tr_30:nth-child(8) td,
.game_table_tr_30:nth-child(9) td,
.game_table_tr_30:nth-child(10) td,
.game_table_tr_30:nth-child(11) td,
.game_table_tr_30:nth-child(12) td,
.game_table_tr_30:nth-child(13) td,
.game_table_tr_30:nth-child(14) td,
.game_table_tr_30:nth-child(15) td {
    border: none !important;
}

/* .game_table_td {
    flex: 1;
    font-size: 8px;
    text-align: center;
    width: 100%;
    height: 100%;
} */

.buttons_div {
    width: 100%;
    margin-top: 30px;
}

.buttons_container, .buttons_container2, .form_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.lebel_side {
    width: 35%;
    text-align: right;
}

.input_side {
    width: 65%;
    padding-left: 5%;
}

.buttons_container2 {
    margin-top: 30px;
}

.left_buttons {
    position: relative;
    width: 100px;
    top: -33px;
}

.left_buttons2 {
    position: relative;
    width: 31%;
}

.right_buttons2 {
    position: relative;
    width: 69%;
}

.btn_up, .btn_down, .btn_left, .btn_right {
    background-color: #dfebf6 !important;
}

.btn_up {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-left: 40%;
}

.btn_down {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.btn_left {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.btn_right {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.right_buttons {
    position: relative;
    width: 200px;
}

.game_inputs_div {
    display: -webkit-flex;
    display: flex;
}

.bottom_buttons_container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.btn {
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: #f2f2f5;
    border-radius: 3px;
    color: black;
    height: 30px;
    width: 30px;
}

.btn_gray {
    background: gray;
    border: 2px solid orange;
}

#doubleSizeBtn {
    width: 61px;
}

#doubleSizeBtn2 {
    width: 61px;
    height: 62px;
}

#bodyText {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 4px;
    font-size: 14px;
}

.header {
    border-bottom: 1px solid black;
    padding-left: 4px;
}

.bigButtonsBody {
    margin-top: 30px;
    margin-bottom: 20px;
    /* background-color: palevioletred; */
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.bodyBtnWhite {
    width: 180px;
    background-color: white;
    color: black;
    font-size: 14px;
    border: 2px solid #3B75C3;
}

.bodyBtnBlue {
    margin-top: 20px;
    width: 180px;
    background-color: #3B75C3;
    color: white;
    font-size: 14px;
    border: 2px solid rgb(18, 87, 177);
}

.smallText {
    height: 20px;
    font-size: 13px;
    color: gray;
    padding-left: 10%;
    margin-top: 2px;
    margin-bottom: 8px;
}

.textBox {
    width: 70%;
    height: 25px;
    border: 1px solid gray;
    border-radius: 4px;
    font-size: 16px;
}

.btnDiv {
    text-align: center;
    margin-bottom: 30px;
}

#centerAllign {
    text-align: center;

}

.greyInputButton{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: gray;
    border-radius: 3px;
    color: black;
    height: 30px;
    width: 30px;
    outline: none;
}

.greyInputButtonSelected{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: gray;
    border: 2px solid orange;
    color: black;
    height: 30px;
    width: 30px;
    outline: none;
}

.gameInputButton{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: #f2f2f5;
    border-radius: 3px;
    color: black;
    height: 30px;
    width: 30px;
    outline: none;
}

.gameInputButtonSelected{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: #f2f2f5;
    border: 2px solid orange;
    color: black;
    height: 30px;
    width: 30px;
    outline: none;
}


@media (min-width: 320px) and (max-width: 480px) {
    .game_table {
        /* width: 300px;
        height: 300px; */
    }
    .game_table_td {
        /* font-size: 8px; */
        text-align: center;
        /* width: 10px;
        height: 10px; */
    }
}

.rowempty {
    display: none;
}

.colempty {
    display: none;
}
.gray_solution {
    background-color: gray;
    height: 12px;
}
.no_gray{
    height: 12px;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;
    line-height: 1.5;
    background-color: #ffffff;
    border: 1px solid black;
    width: 90%;
    height: 300%;
    margin: 5px auto;
}
/* Media query mobile devices */
@media only screen and (min-device-width: 480px) {
    body{
        width: 90%;
    }
}

/* Media query for tablets*/
@media only screen and (min-device-width: 768px) {
    body{
        width: 45% !important;
    }
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {
    body{
        width: 30% !important;
    }
}
