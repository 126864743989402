@import url("https://fonts.googleapis.com/css?family=Varela+Round");

.menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    height: 10vh;
    list-style: none;
    background-color: #424242;
}

.menu-item {
    width: 50px;
    height: 40px;
    transition: background-position-x 0.9s linear;
    text-align: center;
}

.menu-item {
    font-size: 22px;
    color: #777;
    text-decoration: none;
    transition: all 0.45s;
}

.menu-item:hover {
    animation: line 1s;
}

.menu-item:hover a {
    color: #d94f5c;
}

.menu-item:not(:last-child) {
    margin-right: 30px;
}
@keyframes line {
    0% {
        background-position-x: 390px;
    }
}

/* ------------------------------------------------------------------------ */


.board_header{
    font-weight: bold;
    border-bottom: 1px solid black;
}


.top-nav {
    width: 100%;
    overflow: auto;
}

.top-nav a{
    border-bottom: 1px solid black;
}

.top-nav .custom_a {
    text-decoration: none;
    float: left;
    width: 18%;
    text-align: center;
    box-shadow: 1px 0px 0px 0px black;
}

.top-nav a:hover {
    font-weight: bold;
    cursor: pointer;
}

.top-nav_active{
    border-bottom: none !important;
}

.nav_box_setting{
    float: right;
    text-decoration: none;
    text-align: center;
    width: 10%;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){

}

@media only screen and (max-width: 371px){


}