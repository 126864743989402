
.wrapper{

}

.header{
    font-weight: bold;
}

.box1{
    margin-left: 30px;
    margin-right: 30px;
}

.boxbuttonTop, .boxbuttonMiddle {
    background-color: #1E70BD;
    color: white;
    padding: 5px 32px;
    text-align: center;
    font-size: 0.9em;
    letter-spacing: 0.1em;
    margin: 4px 2px;
    margin-top: 30px;
    cursor: pointer;
}

.boxbuttonBottom{
    color: black;
    border: 1px gainsboro solid;
    text-align: center;
    font-size: 0.9em;
    margin: 4px 2px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.myfooter{
    background-color: #7F7F7F;
    color: white;
    font-size: 0.7em;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.myfooter a{
    text-decoration: none;
    color: white;
}


/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){

}

@media only screen and (max-width: 371px){

    .header{
        font-size: 0.9em;
    }

    .boxbuttonBottom, .boxbuttonMiddle, .boxbuttonTop{
        font-size: 0.7em;
    }

    .myfooter{
        font-size: 0.6em;
    }
}