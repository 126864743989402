/* make the table shape square*/
/*write media query for the table so that it can be responsive*/


.footer{
    background-color: #3B75C4;
    text-align: center;
    color: white;
}

.section{
    border: 1px solid black;
    background-color: #BAD7EE;
}

button{
    border-radius: 5px;
    box-shadow: none;
    background-color: #3B75C3;
    color: white;
    padding-top: 0;
    height: 30px;
    float: right;
}

table{
    width: 200px;
    border: none;
}

tbody{
    border: none;
}

td{
}

td, tr{
    border: none;
}

input{
    height: 15px;
    width: 15px;
    box-shadow: none;
    border-style: none;
    float: left;
}

