
#howToPlayDetailsHeader {
    border-bottom: 1px black solid;
    font-weight: bold;
}

#tipTitle {
    margin-top: 5px;
    margin-bottom: 10px;
}

.tableOfContents {
    margin-left: 30px;
    width: 40%;
    overflow: auto;
}

.tableOfContents #title {
    margin-left: 5px;
}

.tableOfContents ol {
    padding-left: 25px;
    margin-top: 0;
    color: #285c99;
}

.content {
    margin-left: 10px;
    margin-right: 15px;
}

.content h3 {
    margin-bottom: 0;
}

.content p {
    margin-top: 0;
}

.content .images {
    height: 100px;
    width: 100px;
    float: right;
    margin-left: 15px;
    margin-bottom: 15px;
    display: inline-block;
}

#secondLastFooter {
    background-color: #DFEAF5;
    text-align: center;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    cursor: pointer;
}

#lastFooter {
    background-color: #4A72C1;
    text-align: center;
    cursor: pointer;
    color: white;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

    #parent_wrapper {
        font-size: 0.8em;
    }
}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px) {

    #parent_wrapper {
        font-size: 0.8em;
    }

    .tableOfContents {
        margin-left: 15px;
    }
}

@media only screen and (max-width: 371px) {

    #parent_wrapper {
        font-size: 0.7em;
    }

    .tableOfContents {
        margin-left: 12px;
    }
}

/* Naima's Design*/

.articleBody {
    padding-left: 1.5%;
    padding-right: 1%;
    margin-bottom: 15%;
}

.letterh4 {
    font-weight: bold;
    font-size: 20px;
    margin-left: 2%;
    padding-left: 10px;
    margin-right: 2%;
}

.letterUn {
    padding-top: 1.8%;
    font-size: 18px;
    margin-left: 2%;
    margin-right: 2%;
    text-decoration: none;
}

.articleBody {
    padding-left: 1.5%;
    padding-right: 1%;
    margin-bottom: 15%;
}

.titlebox {
    background-color: #EEF6E9;
    border-radius: 5px;
    border: none;
    width: 90%;
    margin: 0 auto;
}

.titlebox .a_box {
    display: flex;
}

.num {
    padding-left: 20px;
    padding-bottom: 20px;
    color: #fff;
}

.title_div {
    display: flex;
}

.title_div button {
    width: 50px;
    border: 1px solid #5C8E38;
    border-radius: 5px;
    background-color: #5C8E38;
    margin-bottom: 5%;
}

.title_div span {
    font-size: 18px;
    margin-left: 15px;
    color: #000;
}

.a_title {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.a_title h4 {
    position: relative;
    display: inline-block;
}

.para_title {
    position: relative;
    border-bottom: 4px dashed #AAC680;
    border-left: 8px solid #AAC680;
}

.para_title2 {
    position: relative;
    border: 2px solid #5C8E38;
    background-color: #AAC680;
    border-radius: 5px;
}

.para {
    margin-left: 5%;
    border: none;
    font-size: 16px;
    margin-bottom: 2%;
}