.how_to_play_titles_header{
    border-bottom: 1px solid black;
    font-weight: bold;
    font-size: 17px;
}

.howToPlayTitles{
    padding-left: 26px;
    margin-top: 5px;
}

.howToPlayTitles li{
    cursor: pointer;
    color: blue;
}

.tipTitles{
    padding-left: 26px;
    margin-top: 5px;
}

.tipTitles li{
    cursor: pointer;
    color: blue;
}

.howTo_tip_title_page_footer{
    background-color: #3B75C4;
    text-align: center;
    color: white;
    cursor: pointer;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){

}

@media only screen and (max-width: 371px) {
}

