.sum_input {
    width: 100%;
    height: 100%;
    position: relative;
}
.input_value{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.memo-table {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.memo-table > tbody {
    display: block;
    width: 100%;
}

.memo-table > tbody > tr {
    width: 100%;
    height: 8px;
}

.memo-table > tbody > tr > td {
    width: 20px;
    font-size: 7px;
}

@media (min-width: 320px) and (max-width: 480px) {
    .memo-table > tbody > tr {
        width: 100%;
        height: 6px;
    }
    .memo-table > tbody > tr > td {
        width: 20px;
        font-size: 5px;
    }
}
