.secondNav{
    display: flex;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.secondNav > div{
    box-sizing: border-box;
    width: 33.33%;
    text-align: center;
    border: 1px solid black;
}

.secondNav > div:first-child{
    border-right: none;
}

.secondNav > div:nth-child(2){
    border-right: none;
}

.table-div{
    margin-top: 20px;
    margin-left: 50px;
    margin-right: 50px;
}

.table-containing-boardbygrid{
    width: 100%;
}

.table-containing-boardbygrid td{
    box-sizing: border-box;
    text-align: center;
    font-size: 0.8em;
}

.table-containing-boardbygrid td:nth-child(1){
    width: 10%;
}

.table-containing-boardbygrid td:nth-child(2){
    width: 70%;
}

.table-containing-boardbygrid td:nth-child(3){
    text-align: right;
    width: 20%;
}

.table-containing-boardbygrid button{
    font-size: 0.8em;
    padding-left: 15px;
    padding-right: 15px;
}

.footer{
    cursor: pointer;
    margin-top: 20px;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

@media only screen and (max-width: 479px) {
    .top-nav{
        font-size: 0.8em;
    }

    .secondNav{
        font-size: 0.8em;
        margin-right: 15px;
        margin-left: 15px;
    }

    .table-containing-boardbygrid td{
        font-size: 0.7em;
    }

    .table-containing-boardbygrid button{
        padding-left: 5px;
        padding-right: 5px;
        height: 25px;
    }
    .footer{
        font-size: 0.8em;
    }
}