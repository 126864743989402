.game_div {
    width: 100%;
    height: 47vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.game_table {
    /* background-color:#f2f2f5; */
}

.game_table_submit {
    /* width:min(100%, 47vh);
    height:47vh; */
    display: flex;
    flex-direction: column;
    margin: auto;
}

.game_table_tr_submit{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.game_table_tr {
    display: flex;
    justify-content: center;
}

.game_table_td_submit {
    display: flex;
    /* flex: 1; */
    text-align: center;
}

.game_table_td {
    text-align: center;
}

/* GRID 10x10 */
.game_table_tr_10 td:nth-child(n+6) {
    border: 1px solid black;
}
/* GRID 15x15 */
.game_table_tr_15 td:nth-child(n+9) {
    border: 1px solid black;
}
/* GRID 20x20 */
.game_table_tr_20 td:nth-child(n+11) {
    border: 1px solid black;
}
/* GRID 25x25 */
.game_table_tr_25 td:nth-child(n+14) {
    border: 1px solid black;
}
/* GRID 30x30 */
.game_table_tr_30 td:nth-child(n+16) {
    border: 1px solid black;
}

/* GRID 10x10 */
.game_table_tr_10:nth-child(1) td,
.game_table_tr_10:nth-child(2) td,
.game_table_tr_10:nth-child(3) td,
.game_table_tr_10:nth-child(4) td,
.game_table_tr_10:nth-child(5) td {
    border: none !important;
}

/* GRID 15x15 */
.game_table_tr_15:nth-child(1) td,
.game_table_tr_15:nth-child(2) td,
.game_table_tr_15:nth-child(3) td,
.game_table_tr_15:nth-child(4) td,
.game_table_tr_15:nth-child(5) td,
.game_table_tr_15:nth-child(6) td,
.game_table_tr_15:nth-child(7) td,
.game_table_tr_15:nth-child(8) td {
    border: none !important;
}
/* GRID 20x20 */
.game_table_tr_20:nth-child(1) td,
.game_table_tr_20:nth-child(2) td,
.game_table_tr_20:nth-child(3) td,
.game_table_tr_20:nth-child(4) td,
.game_table_tr_20:nth-child(5) td,
.game_table_tr_20:nth-child(6) td,
.game_table_tr_20:nth-child(7) td,
.game_table_tr_20:nth-child(8) td,
.game_table_tr_20:nth-child(9) td,
.game_table_tr_20:nth-child(10) td {
    border: none !important;
}
/* GRID 25x25 */
.game_table_tr_25:nth-child(1) td,
.game_table_tr_25:nth-child(2) td,
.game_table_tr_25:nth-child(3) td,
.game_table_tr_25:nth-child(4) td,
.game_table_tr_25:nth-child(5) td,
.game_table_tr_25:nth-child(6) td,
.game_table_tr_25:nth-child(7) td,
.game_table_tr_25:nth-child(8) td,
.game_table_tr_25:nth-child(9) td,
.game_table_tr_25:nth-child(10) td,
.game_table_tr_25:nth-child(11) td,
.game_table_tr_25:nth-child(12) td,
.game_table_tr_25:nth-child(13) td {
    border: none !important;
}
/* GRID 30x30 */
.game_table_tr_30:nth-child(1) td,
.game_table_tr_30:nth-child(2) td,
.game_table_tr_30:nth-child(3) td,
.game_table_tr_30:nth-child(4) td,
.game_table_tr_30:nth-child(5) td,
.game_table_tr_30:nth-child(6) td,
.game_table_tr_30:nth-child(7) td,
.game_table_tr_30:nth-child(8) td,
.game_table_tr_30:nth-child(9) td,
.game_table_tr_30:nth-child(10) td,
.game_table_tr_30:nth-child(11) td,
.game_table_tr_30:nth-child(12) td,
.game_table_tr_30:nth-child(13) td,
.game_table_tr_30:nth-child(14) td,
.game_table_tr_30:nth-child(15) td {
    border: none !important;
}

/* .game_table_td {
    flex: 1;
    font-size: 8px;
    text-align: center;
    width: 100%;
    height: 100%;
} */

.buttons_div {
    width: 100%;
    margin-top: 30px;
}

.buttons_container, .buttons_container2, .form_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-end;
}

.lebel_side {
    width: 35%;
    text-align: right;
}

.input_side {
    width: 65%;
    padding-left: 5%;
}

.buttons_container2 {
    margin-top: 30px;
}

.left_buttons {
    position: relative;
    width: 100px;
    top: -33px;
}

.left_buttons2 {
    position: relative;
    width: 31%;
}

.right_buttons2 {
    position: relative;
    width: 69%;
}

.btn_up, .btn_down, .btn_left, .btn_right {
    background-color: #dfebf6 !important;
}

.btn_up {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 40%;
}

.btn_down {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_left {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg);
}

.btn_right {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg);
}

.right_buttons {
    position: relative;
    width: 200px;
}

.game_inputs_div {
    display: flex;
}

.bottom_buttons_container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.btn {
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: #f2f2f5;
    border-radius: 3px;
    color: black;
    height: 30px;
    width: 30px;
}

.btn_gray {
    background: gray;
    border: 2px solid orange;
}

#doubleSizeBtn {
    width: 61px;
}

#doubleSizeBtn2 {
    width: 61px;
    height: 62px;
}

#bodyText {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 4px;
    font-size: 14px;
}

.header {
    border-bottom: 1px solid black;
    padding-left: 4px;
}

.bigButtonsBody {
    margin-top: 30px;
    margin-bottom: 20px;
    /* background-color: palevioletred; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bodyBtnWhite {
    width: 180px;
    background-color: white;
    color: black;
    font-size: 14px;
    border: 2px solid #3B75C3;
}

.bodyBtnBlue {
    margin-top: 20px;
    width: 180px;
    background-color: #3B75C3;
    color: white;
    font-size: 14px;
    border: 2px solid rgb(18, 87, 177);
}

.smallText {
    height: 20px;
    font-size: 13px;
    color: gray;
    padding-left: 10%;
    margin-top: 2px;
    margin-bottom: 8px;
}

.textBox {
    width: 70%;
    height: 25px;
    border: 1px solid gray;
    border-radius: 4px;
    font-size: 16px;
}

.btnDiv {
    text-align: center;
    margin-bottom: 30px;
}

#centerAllign {
    text-align: center;

}

.greyInputButton{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: gray;
    border-radius: 3px;
    color: black;
    height: 30px;
    width: 30px;
    outline: none;
}

.greyInputButtonSelected{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: gray;
    border: 2px solid orange;
    color: black;
    height: 30px;
    width: 30px;
    outline: none;
}

.gameInputButton{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: #f2f2f5;
    border-radius: 3px;
    color: black;
    height: 30px;
    width: 30px;
    outline: none;
}

.gameInputButtonSelected{
    margin-bottom: 1px;
    margin-top: 1px;
    margin-right: 1px;
    background: #f2f2f5;
    border: 2px solid orange;
    color: black;
    height: 30px;
    width: 30px;
    outline: none;
}


@media (min-width: 320px) and (max-width: 480px) {
    .game_table {
        /* width: 300px;
        height: 300px; */
    }
    .game_table_td {
        /* font-size: 8px; */
        text-align: center;
        /* width: 10px;
        height: 10px; */
    }
}

.rowempty {
    display: none;
}

.colempty {
    display: none;
}