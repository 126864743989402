.innerContent{
    margin-left: 30px;
    font-size: 0.7em;
}

.button-div{
    margin-top: 20px;
}

.button-div > button{
    width: 150px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
}

.advertisement-div{
    padding: 10px;
}
