
#parent_wrapper{
    overflow: hidden;
}

#header{
    font-weight: bold;
    border-bottom: 1px solid black;
}

#topBlueButtonDiv{
    width: 100%;
    display: flex;
    margin-top: 23px;
    margin-bottom: 23px;
}

button{
    float: none;
}

#topBlueButtonDiv button{
    background-color: #4A72C1;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

#whiteButton{
    float: right;
    margin-right: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: white;
    color: black;
    border: 2px solid #4A72C1;
}

#group{
    margin-left: 30px;
    margin-right: 30px;
    clear: right;
}


#titleDesign{
    text-align: center;
    width: 100%;
    background: #DEE9F4;
    cursor: pointer;
}

#content{
    display: block;
}

#content table{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

#content table td{
    padding: 0;
}

.start_button{
    border: 1px solid gray;
    border-radius: 5px;
    box-shadow: none;
    background-color: #4A72C1;
    color: white;
    padding-top: 0;
    height: 30px;
    width: 70px;
    margin: 0;
    float: right !important;
}

#footer{
    padding: 3px;
    background-color: #4A72C1;
    color: white;
    text-align: center;
    font-size: 0.9em;
    clear: right;
    cursor: pointer;
}