.jigsaw_table {
    /* table-layout: fixed; */
    width: 100%;
    border: 4px solid black;
    border-collapse: collapse;

    margin-left: auto;
    margin-right: auto;

    font-family: Calibri, sans-serif;
}

.jigsaw_table > tbody > tr > td{
    text-align: center;
    vertical-align: center;
    width: 30px;
    height: 30px;
    white-space: nowrap;
}

.left_border {
    border-left: 3px solid violet !important;
}

.right_border {
    border-right: 3px solid violet !important;
}

.top_border {
    border-top: 3px solid violet !important;
}

.bottom_border {
    border-bottom: 3px solid violet !important;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {
    .jigsaw_table{
        height: 420px;
    }
}

/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .jigsaw_table{
        height: 350px;
    }
}
