.problemInputDot {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.problemInputDot::before {
    background-color: black;
    height: 7%;
    width: 7%;
    border-radius: 50%;
    content: '';
}

.problemInputCross {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.problemInputCross::before, .problemInputCross::after {
    background-color: black;
    height: 100%;
    width: 1%;
    content: '';
    transform: rotate(45deg) translate(0%, 0%);
}
.problemInputCross::after {
    transform: rotate(-45deg);
}

.td_data{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.problemInputGray {
    width: 100%;
    height: 100%;
    background-color: gray;
}

.problem_blank_cell {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}