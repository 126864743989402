
.completed_input {
    position: relative;
}

.completed_input::before {
    content: "X";
    /* font-size: 8px; */
    opacity: .7;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.td_data {    
    width: 100%;
    height: 100%;
}

.dot{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cross{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gray {
    width: 100%;
    height: 100%;
    background-color: gray;
    /* font-size: 10px; */
}

.default_cell {
    /* border: 2px solid black; */
    /* font-size: 10px; */
}

.safe_cell {
    border: 2px solid green;
}

.unsafe_cell {
    border: 2px solid red;
}

.invalid_cell {
    color: #e6336e;
}

/* .problem_cell{
    width: 100%;
    height: 100%;
    text-align: center;
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blank_cell {
    width: 100%;
    height: 100%;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty_problem_cell{
    flex: 0 !important;
    border: none !important;
}

.cell_focused {
    border: 2px solid green !important;
} */

.black_border {
    border: 1px solid black;
}
