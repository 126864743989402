
.board_table > tbody > tr:nth-child(3n) {
    border-bottom: 2px solid black;
}

.board_table > tbody > tr > td:nth-child(3n) {
    border-right: 2px solid black;
}
/*連続入力の色指定部分*/
.active_input{
    background-color: #285c99;
}

.safe_color {
    color: green;
}
.unsafe_color {
    color: #e6336e;
}
.default_color {
    color: black;
}

.selected_cell {
    background-color: #dfebf6;
}
.highlight_cell {
    background-color: #fffecc !important;
}

.btn_continuous_input_active {
    background-color: #285c99 !important;
}
.btn_memo_input_active{
    background-color: #285c99 !important;
}

.selected_input {
    color: gray;
}

.active_level {
    background-color: #ff9900 !important;
}

/* ------------------------------------------------------------------------ */

/* CSS for the sudoku grid */

.board_wrapper{
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 10px;
}

.board_title{
    font-size: 0.8em;
    margin-left: 10px;
}

.board_table {
    /* table-layout: fixed; */
    width: 100%;
    border: 4px solid black;
    border-collapse: collapse;

    margin-left: auto;
    margin-right: auto;

    font-family: Calibri, sans-serif;
}

.board_table > tbody > tr > td{
    text-align: center;
    vertical-align: center;
    width: 30px;
    height: 30px;
    white-space: nowrap;
}

/* CSS for the number selector {1-9}*/
.number_selector_wrapper{
    margin-top: 15px;
    margin-left: 30px;
    margin-right: 30px;
}

.number_selector_wrapper table{
    border: 2px solid black;
    width: 100%;
    border-collapse: collapse;
}

.number_selector_wrapper table td{
    text-align: center;
    border-right: 1px solid black;
}

.number_selector_wrapper table td:hover{
    font-weight: bold;
    cursor: pointer;
}

/* CSS for stacked buttons */

.stacked_buttons_wrapper{
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 15px;
    overflow: hidden;
}

.btn_grp_left{
    float: left;
    height: 80px;
    width: 23%;
    border: 2px solid black;
}

.btn_grp_left table{
    width: 100%;
    height: 100%;
    border-collapse: collapse;
}

.btn_grp_left table .top_row{
    border-bottom: 2px solid black !important;
}

.btn_grp_left table tr:hover{
    font-weight: bold;
    cursor: pointer;
}

.btn_grp_left table td{
    text-align: center;
}


.btn_grp_right_1{
    float: right;
    height: 80px;
    width: 22%;
    border: 2px solid black;
    border-left: none;
}

.btn_grp_right_1 table{
    width: 100%;
    height: 100%;
    border-collapse: collapse;
}

.btn_grp_right_1 table tr:hover{
    font-weight: bold;
    cursor: pointer;
}

.btn_grp_right_1 table td{
    text-align: center;
}

.btn_grp_right_2{
    height: 80px;
    width: 22%;
    float: right;
    border: 2px solid black;
    border-left: none;
}

.btn_grp_right_2 table{
    width: 100%;
    height: 100%;
    border-collapse: collapse;
}

.btn_grp_right_2 table tr:hover{
    font-weight: bold;
    cursor: pointer;
}

.btn_grp_right_2 table td{
    text-align: center;
}


.btn_grp_right_3{
    float: right;
    height: 80px;
    width: 22%;
    border: 2px solid black;
}

.btn_grp_right_3 table{
    width: 100%;
    height: 100%;
    border-collapse: collapse;
}

.btn_grp_right_3 table .top_row{
    border-bottom: 2px solid black;
}

.btn_grp_right_3 table tr{
    width: 100%;
}

.btn_grp_right_3 table tr:hover{
    font-weight: bold;
    cursor: pointer;
}

.btn_grp_right_3 table td{
    text-align: center;
}

.answer_button_div{
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 15px;
    overflow: hidden;
}

.answer_button_div button{
    width: 100%;
    border: 1px solid #4972C1;
    border-radius: 5px;
    background-color: white;
    color: black;
    cursor: pointer;
}

.div_below_answer_btn{
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 15px;
    overflow: hidden;
}

.bigbtn3 {
    background-color: #4972C1;
    border-color: #285c99;
    color: white;
    width: 45%;
    border-radius: 6px;
    float: left;
}

.bigbtn4 {
    background-color: #4972C1;
    border-color: #285c99;
    color: #FFFFFF;
    width: 45%;
    border-radius: 6px;
    float: right;
}

.level_selector_group{
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}

.level_selector_group .level_button {
    background-color: #4972C1;
    border-color: #285c99;
    color: #FFFFFF;
    border-radius: 5px;
    width: 15%;
}

.wrapper_page_end_buttons{
    overflow: hidden;
}

.div_below_level_grp{
    float: left;
    margin-top: 35px;
    margin-left: 15px;
    width: 70%;
}

.group_btn_text{
    overflow: hidden;
}

.bigbtn5 {
    background-color: #4972C1;
    border-color: #285c99;
    color: #FFFFFF;
    width: 35%;
    border-radius: 6px;
    float: left;
    clear: left;
    margin-bottom: 10px;
}

.last_div{
    float: left;
    clear: left;
    margin-top: 5px;
    margin-left: 15px;
    width: 100%;
}

.last_div .top_button{
    margin-top: 0 !important;
}

.bigbtn6 {
    background-color: #629BD2;
    border-color: #285c99;
    color: #FFFFFF;
    width: 50%;
    border-radius: 6px;
    float: left;
    clear: left;
    margin-top: 10px;
}

.letterh4{
    margin-top: 35px;
    margin-left: 15px;
    margin-bottom: 0;
}

.letterh4:hover{
    cursor: pointer;
}

.white_box{
    border: 1px gray solid;
    margin-left: 30px;
    margin-right: 30px;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 25px;
    cursor: pointer;
    margin-bottom: 30px;
}

.white_box a{
    color: black;
    text-decoration: none !important;
}

#last_span{
    font-size: 0.8em;
    color: blue;
    margin-top: 30px;
    cursor: pointer;
    padding-left: 3px;
}

#board_footer{
    background-color: #7F7F7F;
    color: white;
    font-size: 0.8em;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 3px;
    cursor: pointer;
}

#board_footer a{
    text-decoration: none;
    color: white;
}


/* hi-res laptops and desktops */
@media (min-width: 1281px) {
    .board_table{
        height: 420px;
    }
}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .board_table{
        height: 350px;
    }

    .stacked_buttons_wrapper{
        font-size: 0.9em;
    }

    .small_font_row{
        font-size: 0.8em;
    }
}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){
    .stacked_buttons_wrapper{
        font-size: 0.7em;
    }

    .small_font_row{
        font-size: 0.7em;
    }
}

@media only screen and (max-width: 371px){

    .board_table{
        font-size: 0.9em;
        height: 200px;
    }

    .number_selector_wrapper{
        font-size: 0.9em;
    }

    .stacked_buttons_wrapper{
        font-size: 0.6em;
    }

    .small_font_row{
        font-size: 0.5em;
    }
}
