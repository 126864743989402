#hi_rate_parent_wrapper{
    overflow: hidden;
}

#hi_rate_top_nav{
    margin-bottom: 30px;
}

#hi_rate_header{
    font-weight: bold;
    border-bottom: 1px solid black;
}

#hi_rate_group{
    margin-left: 30px;
    margin-right: 30px;
    clear: right;
}

#hi_rate_group table{
    margin: 0 auto;
    width: 100% !important;
}

#hi_rate_group table th{
    text-align: center;
    font-weight: normal;
}

#hi_rate_group table td{
    text-align: center;
}

#table_hide_header th{
    visibility: hidden;
}

#hi_rate_whiteButton{
    float: right;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: white;
    color: black;
    border: 2px solid #4A72C1;
    width: 150px;
}

#hi_rate_footer{
    padding: 3px;
    background-color: #4A72C1;
    color: white;
    text-align: center;
    font-size: 0.9em;
    clear: right;
    cursor: pointer;
}

.hi_rate_start_button{
    border: 1px solid gray;
    border-radius: 5px;
    box-shadow: none;
    background-color: #4A72C1;
    color: white;
    padding-top: 0;
    height: 30px;
    width: 70px;
    margin: 0;
    float: right !important;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){

}

@media only screen and (max-width: 371px){
    #hi_rate_parent_wrapper{
        font-size: 0.7em;
    }

    #hi_rate_whiteButton{
        font-size: 0.7em;
        width: 100px;
        height: 20px;
    }

    .hi_rate_start_button{
        font-size: 0.7em;
        height: 20px;
        width: 50px;
    }

}