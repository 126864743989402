html {
    scroll-behavior: smooth;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 400;
    line-height: 1.5;
    background-color: #ffffff;
    border: 1px solid black;
    width: 90%;
    height: 300%;
    margin: 5px auto;
}
/* Media query mobile devices */
@media only screen and (min-device-width: 480px) {
    body{
        width: 90%;
    }
}

/* Media query for tablets*/
@media only screen and (min-device-width: 768px) {
    body{
        width: 45% !important;
    }
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {
    body{
        width: 30% !important;
    }
}