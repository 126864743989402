* {
    box-sizing: border-box;
}

.illustrologyMainNavbar{
    display: flex;
}

.illustrologyMainNavbar > a{
    width: 50%;
    text-align: center;
    border-bottom: 1px solid #000;
    text-decoration: none;
    color: black;
}

.illustrologyMainNavbar > a:first-child{
    border-right: 1px solid #000;
}

.active-navbutton{
    border-bottom: none !important;
}

.select-by-difficulty{
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.blue-title{
    text-align: center;
    border: 1px solid #9FC2E3;
}

.centerButton{
    position: relative;
    left: 50%;
    transform: translate(-50%);
}

.button-illustrology-main{
    width: 100px;
}

.select-by-difficulty > .first-button{
    margin-top: 20px;
}

.select-by-difficulty > button{
    margin-top: 2px;
}

.select-by-grid{
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.select-by-grid > .first-button{
    margin-top: 20px;
}

.select-by-grid > button{
    margin-top: 2px;
}

.new-questions-div{
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.new-questions-div > table{
    width: 100%;
    margin-top: 20px;
    text-align: center;
    border-collapse: collapse;
}

.new-questions-div > table th{
    background-color: #BFD6ED;
    font-weight: normal;
    font-size: 0.7em;
    border: 1px solid #9FC2E3;
}

.new-questions-div > table td{
    font-size: 0.7em;
    border: 1px solid #9FC2E3;
}

.article-div{
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
}

.article-div li{
    font-size: 0.7em;
}

.article-div li:hover{
    color: blue;
    cursor: pointer;
}

.advertisement-div{
    border: 1px solid #E2E3E3;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 20px;
    text-align: center;
    padding: 20px;
}

.link{
    color: blue;
    display: block;
    margin-top: 20px;
    font-size: 0.8em;
    cursor: pointer;
}

.footer-illustrology-main{
    background-color: #7F7F7F;
    height: 45px;
    position: relative;
}

.footer-illustrology-main > a{
    text-decoration: none;
    color: white;
    font-size: 0.7em;
    position: absolute;
    top: 3px;
    left: 5px;
}

#footer-last-link{
    top: 25px;
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {

}


/* Media query for tablets*/
@media only screen and (min-width: 768px) and (max-width: 1280px) {

}


/* Media query mobile devices */
@media only screen and (min-width: 480px) and (max-width: 767px){

}

/* Media query extra-small mobile devices */
@media only screen and (min-width: 372px) and (max-width: 479px){

}

@media only screen and (max-width: 371px) {
    .select-by-difficulty{
        margin-left: 15px;
        margin-right: 15px;
        font-size: 0.8em;
    }

    .select-by-grid{
        margin-left: 15px;
        margin-right: 15px;
        font-size: 0.8em;
    }

    .new-questions-div{
        margin-left: 15px;
        margin-right: 15px;
        font-size: 0.8em;
    }

    .article-div{
        margin-left: 15px;
        margin-right: 15px;
        font-size: 0.8em;
    }

    .advertisement-div{
        margin-left: 15px;
        margin-right: 15px;   
        font-size: 0.8em;     
    }
}

